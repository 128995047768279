import { getDurationToNowInHours, getTimezone } from './helper';
import { JobStatus } from '../__generated__/graphql';
import { FragmentType, gql, useFragment as fragment } from '../__generated__';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(tz);

const timezone = getTimezone();

export const ReviewableOrTippableJobFragment = gql(/* GraphQL */ `
  fragment ReviewableOrTippableJob on Job {
    id
    rawId
    invoicedDate
    status
    reviews {
      id
      stars
    }
    advancedReview {
      id
      stars
    }
    customerTip {
      amount
    }
  }
`);

export const jobIsReviewableOrTippable = (
  jobFragment?: FragmentType<typeof ReviewableOrTippableJobFragment> | null,
): [
  boolean | undefined,
  {
    isOld: boolean;
    hasReview: boolean;
    hasTip: boolean;
    hasUnreviewableStatus: boolean;
  },
] => {
  if (!jobFragment)
    return [
      undefined,
      {
        isOld: false,
        hasReview: false,
        hasTip: false,
        hasUnreviewableStatus: false,
      },
    ];

  const job = fragment(ReviewableOrTippableJobFragment, jobFragment);
  if (job.status === JobStatus.Invoiced && !job.invoicedDate) {
    // eslint-disable-next-line no-console
    console.warn(
      'jobIsReviewableOrTippable: job.invoicedDate is null for invoiced job id: ',
      job.rawId,
    );
  }

  const isOld =
    job &&
    getDurationToNowInHours(dayjs(job.invoicedDate).tz(timezone).toDate()) <
      -30 * 24;
  const hasReview = Boolean(job?.reviews?.length || job?.advancedReview?.stars);
  const hasTip = Boolean(job?.customerTip?.amount);
  const hasUnreviewableStatus = job?.status !== JobStatus.Invoiced;

  // within 30 days of invoice, and doesn't have ALL of the following:
  // 1. a review
  // 2. an advanced review
  // 3. a tip
  // If all 3 are present, redirect to the appointment page -- nothing a user can do here
  const isReviewableOrTippable =
    !hasUnreviewableStatus && !isOld && (!hasReview || !hasTip);

  return [
    isReviewableOrTippable,
    {
      isOld,
      hasReview,
      hasTip,
      hasUnreviewableStatus,
    },
  ];
};
