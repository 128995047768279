import dynamic from 'next/dynamic';
import { PropsWithChildren, useEffect, useState } from 'react';
import * as Sentry from '@sentry/nextjs';
import { BsCarFrontFill, BsHouseDoorFill } from 'react-icons/bs';

type Region = {
  lat: number;
  lng: number;
};

const GoogleMapReact = dynamic(() => import('google-map-react'), {
  loading: () => null,
  ssr: false,
});

// Return map bounds based on list of places
const getMapBounds = (maps: typeof google.maps, places: Region[]) => {
  const bounds = new maps.LatLngBounds();

  places.forEach((place) => {
    bounds.extend(new maps.LatLng(place.lat, place.lng));
  });

  return bounds;
};

// this allows us to pass GPS points to the marker
function MapMarkerContainer(props: PropsWithChildren & Region) {
  return (
    <div className="flex justify-center items-center rounded-full bg-white w-[24px] h-[24px]">
      {props.children}
    </div>
  );
}

type TravelMapProps = {
  destination: Region;
  cleanerLocation?: Region | null;
};

type MapInternals = {
  map?: google.maps.Map;
  maps?: typeof google.maps;
};

export function TravelMap({ destination, cleanerLocation }: TravelMapProps) {
  const [{ map, maps }, setMapsApiInternals] = useState<MapInternals>({});

  useEffect(() => {
    if (!map || !maps) return;

    // Get bounds between the destination and the cleaner
    const places = cleanerLocation
      ? [cleanerLocation, destination]
      : [destination];

    const bounds = getMapBounds(maps, places);
    const equalBounds = bounds
      ?.getNorthEast()
      ?.equals?.(bounds?.getSouthWest());

    // Fit map to bounds
    if (equalBounds) return;
    map.fitBounds(bounds);
  }, [map, maps, cleanerLocation, destination]);

  if (!process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY) return null;
  return (
    <Sentry.ErrorBoundary fallback={<div />}>
      <GoogleMapReact
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={setMapsApiInternals}
        bootstrapURLKeys={{
          key: process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY,
        }}
        options={{
          scrollwheel: false,
          disableDoubleClickZoom: true,
          disableDefaultUI: true,
        }}
        center={{
          lat: destination.lat,
          lng: destination.lng,
        }}
        zoom={13}
      >
        <MapMarkerContainer lat={destination.lat} lng={destination.lng}>
          <BsHouseDoorFill
            size={16}
            className="fill-darkerGreen flex-shrink-0"
          />
        </MapMarkerContainer>
        {cleanerLocation ? (
          <MapMarkerContainer
            lat={cleanerLocation?.lat}
            lng={cleanerLocation?.lng}
          >
            <BsCarFrontFill
              size={16}
              className="fill-darkPurple flex-shrink-0"
            />
          </MapMarkerContainer>
        ) : null}
      </GoogleMapReact>
    </Sentry.ErrorBoundary>
  );
}
