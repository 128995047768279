import React from 'react';
import BlockTitle from '../ui/BlockTitle';
import AppointmentTile from '@/components/ui/AppointmentTile';
import { JobListDetailsFragment } from 'src/__generated__/graphql';
import Button from '@/components/ui/Button';
import Skeleton from 'react-loading-skeleton';

interface AppointmentListProps {
  jobs: Array<JobListDetailsFragment>;
  loading: boolean;
  fetchMore: () => void;
  hasMore: boolean;
  title: string;
  emptyListMessage: string;
  lmcHours: number;
}

const AppointmentList = ({
  jobs,
  loading,
  fetchMore,
  hasMore,
  title,
  emptyListMessage,
  lmcHours,
}: AppointmentListProps) => {
  if (loading && jobs.length === 0) return <Skeleton className="h-[250px]" />;

  return (
    <div className="mt-4 sm:mx-2 md:mx-4 lg:mx-0">
      <BlockTitle text={title} />

      {jobs.length > 0 ? (
        <div>
          {jobs.map((job, index) => {
            return (
              <React.Fragment key={index}>
                <AppointmentTile job={job} lmcHours={lmcHours} />
                {index + 1 < jobs.length ? <div className="h-4" /> : null}
              </React.Fragment>
            );
          })}
          {hasMore && (
            <Button
              className="mt-4 w-full"
              size="sm"
              loading={loading}
              onClick={fetchMore}
              aria-label={`Load more ${title}`}
            >
              Load more
            </Button>
          )}
        </div>
      ) : (
        <p>{emptyListMessage}</p>
      )}
    </div>
  );
};

export default AppointmentList;
