import { useEffect, useMemo, useState } from 'react';
import NoUpcomingTile from '@/components/ui/NoUpcomingTile';
import { useQuery } from '@apollo/client';
import AppointmentList from '@/components/features/AppointmentList';
import Button from '@/components/ui/Button';
import { throwIfUnauthenticatedCustomer } from '@/utils/narrow-utils';
import isDefined from '@/utils/isDefined';
import { useLayoutControls } from '@/hooks/useLayoutControls';
import Banners from './components/Banners';
import Alerts from './components/Alerts';
import Modals from './components/Modals';
import useGetFutureJobs from './hooks/useGetFutureJobs';
import useGetPastJobs from './hooks/useGetPastJobs';
import useGetCancelledJobs from './hooks/useGetCancelledJobs';
import { CUSTOMER_DATA } from './utils';
import useGetLMCPolicy from '../LastMinuteCancellationPolicy/useGetLMCPolicy';

export default function Dashboard() {
  const [showCancelled, setShowCancelled] = useState(false);
  const { clearBackButtonAction } = useLayoutControls();

  useEffect(() => {
    clearBackButtonAction();
  }, [clearBackButtonAction]);

  const { futureJobsData, jobList, futureJobsLoading, fetchMoreFutureJobs } =
    useGetFutureJobs();
  const { pastJobsData, pastJobList, pastJobsLoading, fetchMorePastJobs } =
    useGetPastJobs();
  const {
    cancelledJobsData,
    cancelledJobList,
    cancelledJobsLoading,
    fetchMoreCancelledJobs,
  } = useGetCancelledJobs();

  const { lmcHours } = useGetLMCPolicy();
  const { data, loading: customerDataLoading } = useQuery(CUSTOMER_DATA, {
    variables: {
      keysWithOptions: [
        { key: 'new_fc_cancellation_flow_v4', autoAssign: false },
      ],
    },
  });
  const customerData = throwIfUnauthenticatedCustomer(data);

  const experiments = useMemo(() => {
    if (!customerData?.me?.experiments) {
      return Object.create(null) as Record<string, string | undefined | null>;
    }
    return Object.fromEntries(
      customerData.me.experiments
        .filter(isDefined)
        .map(({ key, value }) => [key, value]),
    );
  }, [customerData?.me?.experiments]);

  const prepaidCanBookWithoutCC = useMemo(() => {
    return (
      customerData?.me?.canBookWithoutCreditcard &&
      customerData?.me?.savedPaymentMethod?.funding === 'prepaid' &&
      customerData?.me?.savedPaymentMethod?.walletType === 'card'
    );
  }, [
    customerData?.me?.canBookWithoutCreditcard,
    customerData?.me?.savedPaymentMethod,
  ]);

  return (
    <>
      <Modals
        customerData={customerData}
        customerDataLoading={customerDataLoading}
        jobList={jobList}
        prepaidCanBookWithoutCC={prepaidCanBookWithoutCC}
      />
      <Banners
        customerData={customerData}
        customerDataLoading={customerDataLoading}
        experiments={experiments}
      />
      <Alerts
        customerData={customerData}
        customerDataLoading={customerDataLoading}
        prepaidCanBookWithoutCC={prepaidCanBookWithoutCC}
        jobList={jobList}
        pastJobList={pastJobList}
        cancelledJobList={cancelledJobList}
      />

      {!futureJobsLoading && jobList.length === 0 ? (
        <NoUpcomingTile
          hasVoucher={
            !!customerData?.me?.vouchers?.some(
              (voucher) => voucher?.merchant === 'dhj',
            )
          }
          hasAnyJobs={pastJobList.length > 0 || cancelledJobList.length > 0}
        />
      ) : (
        <AppointmentList
          jobs={jobList}
          loading={futureJobsLoading}
          fetchMore={fetchMoreFutureJobs}
          hasMore={
            futureJobsData?.me?.__typename === 'Customer' &&
            !!futureJobsData?.me.jobs?.pageInfo?.hasNextPage
          }
          title={'Upcoming appointments'}
          emptyListMessage={'You have no upcoming appointments'}
          lmcHours={lmcHours}
        />
      )}

      {pastJobList.length > 0 && (
        <>
          <div className="h-4" />
          <AppointmentList
            jobs={pastJobList}
            loading={pastJobsLoading}
            fetchMore={fetchMorePastJobs}
            hasMore={
              pastJobsData?.me?.__typename === 'Customer' &&
              !!pastJobsData?.me.jobs?.pageInfo?.hasNextPage
            }
            title={'Past appointments'}
            emptyListMessage={'You have no past appointments'}
            lmcHours={lmcHours}
          />
        </>
      )}

      {cancelledJobList.length > 0 && (
        <>
          <div className="h-4" />

          {showCancelled ? (
            <AppointmentList
              jobs={cancelledJobList}
              loading={cancelledJobsLoading}
              fetchMore={fetchMoreCancelledJobs}
              hasMore={
                cancelledJobsData?.me?.__typename === 'Customer' &&
                !!cancelledJobsData?.me.jobs?.pageInfo?.hasNextPage
              }
              title={'Cancelled appointments'}
              emptyListMessage={'You have no cancelled appointments'}
              lmcHours={lmcHours}
            />
          ) : (
            <div className="sm:mx-2 md:mx-4 lg:mx-0">
              <Button
                className="w-full"
                size="sm"
                variant="secondary"
                onClick={() => setShowCancelled(true)}
              >
                Show cancelled appointments
              </Button>
            </div>
          )}
        </>
      )}
    </>
  );
}
