import { useQuery } from '@apollo/client';
import { gql } from 'src/__generated__';

export const LMC_POLICY_QUERY = gql(/* GraphQL */ `
  query LMCPolicyQuery {
    newLmcPolicy
    lmcHours
    lmcFlatFee
  }
`);

const useGetLMCPolicy = () => {
  const { data } = useQuery(LMC_POLICY_QUERY);
  const { newLmcPolicy, lmcHours, lmcFlatFee } = data || {};

  return {
    newLmcPolicy: newLmcPolicy === undefined ? true : !!newLmcPolicy,
    lmcHours: lmcHours || 6,
    lmcFlatFee: lmcFlatFee || 40,
  };
};

export default useGetLMCPolicy;
