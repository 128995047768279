import { useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { jobListLens, JOBS_LIST } from './utils';
import {
  JobsListQuery,
  JobsListQueryVariables,
  JobSortDirection,
  JobStatus,
} from 'src/__generated__/graphql';

import { dateStringIsToday, endOfToday } from '@/utils/helper';

const pastJobsVariables: JobsListQueryVariables = {
  filter: {
    jobStatus: ['claimed', 'submitted', 'pending_invoice', 'invoiced'],
    // to show jobs that have been completed TODAY, we set the start lte to be TOMORROW
    // We just need to then filter out jobs that are today and claimed, submitted, or pending invoice
    startLte: endOfToday()?.toISOString() || new Date().toISOString(),
    sortDir: JobSortDirection.Desc,
  },
  first: 2,
};

const useGetPastJobs = () => {
  const {
    data: pastJobsData,
    fetchMore: _fetchMorePastJobs,
    loading: pastJobsLoading,
  } = useQuery<JobsListQuery, JobsListQueryVariables>(JOBS_LIST, {
    variables: pastJobsVariables,
  });
  const pastJobList = useMemo(
    () => jobListLens(pastJobsData),
    [pastJobsData],
  ).filter((job) => {
    // if job is not today, return true
    // if job is today, show it in this list only if it has been invoiced
    if (!dateStringIsToday(job.start) || job.status === JobStatus.Invoiced)
      return true;
    return false;
  });
  const fetchMorePastJobs = useCallback(() => {
    _fetchMorePastJobs({
      variables: {
        after:
          pastJobsData?.me?.__typename === 'Customer'
            ? pastJobsData.me.jobs?.pageInfo?.endCursor
            : null,
      },
    });
  }, [_fetchMorePastJobs, pastJobsData]);

  return { pastJobsData, pastJobList, pastJobsLoading, fetchMorePastJobs };
};

export default useGetPastJobs;
